.react-calendar {
    width: 98%;
    height: 100%;
    margin: 0px 39.3px 0.1px 0;
    padding: 27px 43.5px 39.9px 39px;
    border: transparent;
    border-radius: 12px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
}
.react-calendar--doubleView {
    width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
/* .react-calendar button {
  color: #032541;
  margin: 0;
  border: 0;
  outline: none;
} */
.react-calendar button:enabled:hover {
    cursor: pointer;
}
.react-calendar__navigation {
    display: flex;
    height: 52px;
    margin-bottom: 1em;
}
/* .react-calendar__navigation button {
  font-family: Poppins;
  font-size: 20px;
  object-fit: contain;
  color: #dc3545;
} */
.react-calendar__navigation button {
    height: 28px;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: #032541;
}

.react-calendar__navigation button .react-calendar__navigation button:disabled {
    background-color: #f0f0f000;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e600;
}
.react-calendar__month-view__weekdays {
    font-family: Poppins;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    color: #032541;
}
.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
    color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
    color: #75757500;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}
.react-calendar__tile {
    max-width: 100%;
    max-height: 65px;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 20px;
    color: #032541;
    font-size: 12px;
}
.react-calendar__tile:disabled {
    background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #e6e6e600;
}
.react-calendar__tile--now {
    color: crimson;
}
.react-calendar__tile--now:enabled:hover,
    /* .react-calendar__tile--now:enabled:focus {
      background: #ffffa9;
    } */
.react-calendar__tile--hasActive {
    background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
}
.react-calendar__tile--active {
    color: crimson;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: transparent;
}
.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}
