body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: "Poppins";
    src: local("Poppins"),
    url("../src/common/font/Poppins-Regular.ttf") format("truetype");
    font-weight: normal;
}

.lihover:hover{
    background-color: "white";
}

::-webkit-scrollbar {
    width: 10px;
  }

  /* button styles */
  #cancel-category{
    border:solid 1px #032541 !important;
    color:#032541 !important;
  }

  #cancel-category:disabled{
    border: solid 1px rgba(0, 37, 67, 0.4) !important;
    color:rgba(3, 37, 65, 0.4) !important;
  }

  #preview-category{
    background-color:#032541;
    color: #fff;
  }

  #preview-category:disabled{
    background-color:rgba(3, 37, 65, 0.4);
    color: #fff;
  }


  /* customized tab styles */
  .tab{
    width: 115px !important;
    height: 40px !important;
    margin: 0 10px !important;
    padding: 8.5px 19px 8.5px 20px !important;
    border-radius: 5px !important;
    background-color: #f5f6f7 !important;
  }

#biz-select{
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
     
}

#t-type{
    text-transform: capitalize !important;
}

@media (max-width: 768px) {

    #unauth-payment-container{
        overflow-x: hidden;
        width: 100%;
        padding-top: 10px !important;
        display: flex;
        margin-left: -25px !important;
    }

    #payment-grid{
        display: flex;
        flex-direction: column;
        width: 100% !important;
        align-items: flex-start;
        justify-content: flex-start;
        margin-left: 0px !important;
        padding: 0px !important;
    }

    #payment-container{
        display: flex !important;
        width: 100% !important;
        align-items: flex-start;
        justify-content: flex-start;
    }

    #grid-text{
        width: 100% !important;
        margin-top: 30px !important;
    }

    .payment-card{
        display: flex !important;
        width: 330px !important;
        justify-content: center !important;
    }

    /* payment modal styles */
    .payment-form{
        width: 100% !important;
        margin-top: 30px !important;
        margin-right: 0px !important;
        /* margin-left: -10px; */
    }

    .inner-payment-grid{
        display: flex;
        width: 100% !important;
        padding: 0px !important;
        margin-left: -10px !important;
    }

    .card-info{
        display: flex;
    }

    .grid-item{
        width: 100% !important;
    }

    .grid-container{
        width: 100% !important;
    }

    .input-field{
        display: flex;
        width: 300px !important;
    }

    #pay-now, #generate-link, #send-prompt{
        width: 300px !important;
    }
}