.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination li {
    display: inline-block;
    margin: 0 5px;
    cursor: pointer;
    padding: 5px;
}

.pagination li.active {
    font-weight: bold;
}
