.sync-icon {
    animation: spin 3s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.spinning {
    animation-play-state: running;
}

@media (max-width: 768px) {

    #auth-invoice{
        display: flex;
        flex-direction: column;
        width: 100% !important;
        /* padding: 5px; */
        overflow-x: hidden !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        padding: 0px !important;
        height: auto;
    }

    .invoice-contents{
        display: flex;
        max-width: 100%;
        overflow-x: hidden !important;
        margin: 0px !important;
    }

    #holder-container{
        width: 100% !important;
        padding: 0px !important;
    }


    #downloadable{
      display: flex;
      flex-direction: column;
      width: 100% !important;
      font-size: .9rem !important;
      padding: 5px;
    }

    #invoice-header{
        display: flex !important;
        justify-content: space-between !important;
        width: 100% !important;
        padding: 5px;
        height: 120px !important;
    }

    #big-invoice{
        display: flex !important;
        font-size: 30px !important;
    }

    #date-text{
        font-size: 10px !important;
    }

    #invoice-status{
        display: flex;
        font-size: 25px !important;
    }

    #invoice-header span{
        /* font-size: 1rem !important; */
    }

    #invoice-from{
        display: flex;
        flex-direction: column !important;
        align-items: flex-start !important;
        justify-content: flex-start !important;
        margin-top: 10px;
    }

    #billed{
        display: flex;
        flex-direction: column;
        text-align: left !important;
        margin-top: 10px;
    }

    #invoice-header-details{
        display: flex;
    }

    #invoice{
        font-size: 20px !important;
    }

    #from-details{
        display: flex;
    }

    #from-details span{
        font-size: 12px !important;
    }

    .date{
        font-size: 10px;
    }
    #amount-details-container{
        display: flex;
        width: 100% !important;
        justify-content: flex-end !important;
    }

    #amount-details-contents{
        display: flex;
        justify-content: center !important;
        height: 130px;
        padding: 10px !important;
        width: 60% !important;
    }

    #subtotal, #discount, #total{
        display: flex;
        width: 100% !important;
    }

    #subtotal span, #discount span, #total span{
        margin-right: 20px !important;

    }

    #payment-info{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #t-cell{
        font-weight: 700 !important;
        font-size: 12px !important;
        width: 100% !important;
        padding: 0px 10px;
    }
    #payments{
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        margin-top: 30px;
    }
    .icon{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }


    .icon span{
        margin-left: 10px;
    }

    #payments img{
        max-width: 80%;
    }

    #pay{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }

    #footer-text{
        display: flex;
        flex-direction: column;
        align-items: center !important;
        justify-content: center !important;
    }

    #footer-text div{
        display: flex;
        flex-direction: column;
        align-items:center;
        justify-content: center !important;
    }

    #footer-text-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 80%;
    }

    #footer-info{
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    #footer-info-email{
        margin-top: -10px;
    }
  }
